import '~styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';

import type {AppProps} from 'next/app';
import {appWithTranslation} from 'next-i18next';
import {ToastContainer} from 'react-toastify';
import TagManager from 'react-gtm-module';
import {useEffect} from 'react';

import Providers from '~components/providers';

function MyApp({Component, pageProps}: AppProps) {
    useEffect(() => {
        const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
        if (gtmId) {
            TagManager.initialize({gtmId});
        }
    }, []);

    return (
        <Providers>
            <Component {...pageProps} />
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Providers>
    );
}

export default appWithTranslation(MyApp);
