import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';
import {createContext, ReactNode, useMemo} from 'react';
import {UseTranslationResponse} from 'react-i18next';

type OptimizedTranslateContextType = UseTranslationResponse<string, undefined>;

export const OptimizedTranslateContext =
    // @ts-ignore
    createContext<OptimizedTranslateContextType>(null);

export default function OptimizedTranslateProvider({
    children,
}: {
    children: ReactNode;
}) {
    const router = useRouter();

    const {t, ...rest} = useTranslation('ERROR: check optimized next translate');

    const value = useMemo(
        () => ({
            t,
            ...rest,
        }),
        [router.pathname],
    );

    return (
        <OptimizedTranslateContext.Provider value={value}>
            {children}
        </OptimizedTranslateContext.Provider>
    );
}
