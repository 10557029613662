module.exports = {
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Avast extension error
        '_avast_submit',

        //Custom Errors
        'User rejected the request.',
        'The operation was aborted.',
        'encountered an error while attempting to update latest block',
        'Callback was already called.',
        'No RPC Url available for chainId',
        'Non-Error promise rejection captured with keys',
        'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
        'JSON RPC response format is invalid',
        'Document is not focused',
        'Read permission denied',
        'out of memory',
        "Cannot assign to read only property 'ethereum' of object",
        'Syntax error',
        'getInitialProps missing data at path: /wp-plain.php',
        "undefined is not an object (evaluating 'p.manager')",
        'invalid address (argument="address", value',
        "Cannot read properties of undefined (reading 'slice')",
        'Permission denied to access property',
        'Cancel rendering route',
        'The user aborted a request',
        'User canceled',
        'Hydration failed because the initial UI',
        'There was an error while hydrating',
        'The play() request was interrupted by a call to pause()',
        'Unrecognized chain ID',
        'googlefc is not defined',
        '__cmp is not defined',
        'apstagLOADED is not defined',
        'The transaction was cancelled',
        'Provider already initialized.',
        'moat_px is not defined',
        'refreshData is not defined',
    ],
    denyUrls: [
        /chrome-extension/i,
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /^chrome-extension:\/\//i,
    ],
};
