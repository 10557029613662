import dayjs from 'dayjs';
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {useScopeTranslation} from '~hooks/useScopeTranslation';

type CountdownProviderContextType = {
    date: string | null;
    isCountdownCompleted: boolean;
};

const CountdownProviderContext = createContext<CountdownProviderContextType>({
    date: null,
    isCountdownCompleted: false,
});

export default function CountdownProvider({children}: {children: ReactNode}) {
    const {t} = useScopeTranslation('common', 'countdown');

    const date = useMemo(() => t('date'), [t]);

    const [isCountdownCompleted, setIsCountdownCompleted] = useState<boolean>(
        () => !!date && dayjs().unix() >= dayjs(date).unix(),
    );
    const value = useMemo<CountdownProviderContextType>(
        () => ({
            date,
            isCountdownCompleted,
        }),
        [date, isCountdownCompleted],
    );

    useEffect(() => {
        if (!isCountdownCompleted && date) {
            setTimeout(() => {
                setIsCountdownCompleted(true);
            }, dayjs(date).valueOf() - dayjs().valueOf());
        }
    }, [date]);

    return (
        <CountdownProviderContext.Provider value={value}>
            {children}
        </CountdownProviderContext.Provider>
    );
}

export function useCountdown() {
    return useContext(CountdownProviderContext);
}
