// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import ignoreSentry from './ignoreSentry';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn:
        SENTRY_DSN ||
        'https://f5ceef6a67d4a16dc139ae84082f9f73@o4505040852418560.ingest.sentry.io/4505635581001728',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    ignoreErrors: [...ignoreSentry.ignoreErrors],
    denyUrls: ignoreSentry.denyUrls,
    hideSourceMaps: true,
});
