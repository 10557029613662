import {useContext, useMemo} from 'react';
import {UseTranslationResponse} from 'react-i18next';

import {OptimizedTranslateContext} from '~components/providers/OptimizedTranslate';

export const useScopeTranslation = (namespace: string, scope: string = '') => {
    const {t, ...rest} = useContext(OptimizedTranslateContext);

    return useMemo<UseTranslationResponse<string, undefined>>(
        () => ({
            t: (key: string, options) => {
                const overrideNs = key.includes(':');

                return t(
                    `${
                        overrideNs ? '' : `${namespace}:${scope}${scope ? '.' : ''}`
                    }${key}`,
                    options,
                );
            },
            ...rest,
        }),
        [namespace, scope, t],
    );
};
