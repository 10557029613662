import {ReactNode} from 'react';
import {MotionConfig} from 'framer-motion';
import isValidProp from '@emotion/is-prop-valid';

import BreakpointsProvider from './BreakpointsProvider';
import OptimizedTranslateProvider from './OptimizedTranslate';
import CountdownProvider from './CountdownProvider';

type ProvidersProps = {
    children: ReactNode;
};

const Providers = ({children}: ProvidersProps) => {
    return (
        <MotionConfig isValidProp={isValidProp}>
            <OptimizedTranslateProvider>
                <CountdownProvider>
                    <BreakpointsProvider>{children}</BreakpointsProvider>
                </CountdownProvider>
            </OptimizedTranslateProvider>
        </MotionConfig>
    );
};

export default Providers;
